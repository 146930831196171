import { Col, Layout, Menu, Row, Select } from "antd";

import { Vista } from "@essenza/react";
import { useModel, useVista, useGraph, useApp } from "@essenza/react";
import { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { JobModel } from "../models/jobmodel";
import { JobOpen } from "../view/JobOpen";
import { JobSent } from "../view/JobSent";
import { JobDetail } from "../view/jobdetail";
import { JobSaved } from "../view/JobSaved";

const { Sider, Content } = Layout;
const { Option } = Select;

export function JobVistaController(c) {
    c.skin = JobVista;
    let store = null;
    c.command = {
        SEARCH: (filter, { data, node }) => {
            if (!store || c.changed){
                store = data;
                c.changed = false;
            }
                
            const area = filter.area;
            c.setSource(node.getKey(), store.filter((item) => (area === '' || item.roles === area)));
        }
    }
}

export function JobVista(info) {
    const [ctx, control] = useVista(JobVistaController);
    const [model] = useModel();
    const location = useLocation();
    const [index, setIndex] = useState(location.state || "0");
    const filter = useRef({ area: '', index: 0 }).current;
    const open = useGraph(JobModel, "open");
    const sent = useGraph(JobModel, "applied");
    const saved = useGraph(JobModel, "saved");
    const source = [open, sent, saved][index];
    console.log("JOB-VISTA", index);

    useEffect(() => {
        if (model) {
            if (index === "0") {
                model.read(JobModel, m => m.open());
            }
            else if (index === "1") {
                model.read(JobModel, m => m.sent());
            }
            else
                model.read(JobModel, m => m.saved());

            control.store = null;
            control.changed = true;
        }
    }, [index, model]);

    return (
        <Vista context={ctx} >
            <Layout style={{ width: '100%' }}>
                <Sider className='layout-sider sider-custom h-main'>
                    <Menu onClick={({ key }) => setIndex(key)} items={menuItems} selectedKeys={index} defaultSelectedKeys={['0']}></Menu>
                </Sider>
                <Content >
                    <div className="secondary-header  secondary-header-shadow">
                        <Row  className="max-width px-sm">
                            <Col flex="auto" style={{ display: "flex", alignItems: "center" }}>
                                <h1 style={{paddingLeft: '54px'}}>{['Posizioni Aperte', 'Candidature Inviate', 'Annunci Salvati'][index]}</h1>
                            </Col>
                            <Col flex="none">
                                <Select onChange={(v) => { filter.area = v; control.execute("SEARCH", filter, source) }} placeholder="Filtra per categoria" style={{ width: '250px', height: '50px', padding: '0' }}>
                                    <Option value="">Tutti i settori</Option>
                                    <Option value="Marketing">Marketing</Option>
                                    <Option value="Controllo di Gestione">Controllo di Gestione</Option>
                                    <Option value="Tecnologie Industriali">Tecnologie Industriali</Option>
                                </Select>
                            </Col>
                        </Row>
                    </div>

                    <section className="bg-sec h-sec" style={{overflow: "auto"}}>
                        <div className="max-width">
                            {index === "0" && <JobOpen source={open?.get()} />}
                            {index === "1" && <JobSent source={sent?.get()} />}
                            {index === "2" && <JobSaved source={saved?.get()} />}
                        </div>
                    </section>
                </Content>
            </Layout>
        </Vista>
    )
}

const menuItems = [
    { label: 'Posizioni Aperte', key: "0" }, // remember to pass the key prop
    { label: 'Candidature Inviate', key: "1" },
    { label: 'Annunci Salvati', key: "2" }
];