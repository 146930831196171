import { ApiService } from "@essenza/core";

export function JobService() {
  ApiService.call(this);

  this.formatData = (item) => {
    const data = {idjob: item.id, update: item.idjoboffer > 0};
    if(!item.idjoboffer)
      item.idjoboffer = item.id;
    return data;
  }

  this.save = (item) => {
    const data = this.formatData(item);
    data.state = !item.saved;
    return this.ExecuteApi("save", data).then( () => item.saved = !item.saved );
  };

  this.apply = (item) => {
    return this.ExecuteApi("apply", this.formatData(item));
  };

  this.seen = (item) => {
    return this.ExecuteApi("seen", this.formatData(item)).then( () => item.seen = true );
  };

  this.send = (data) => {
    return this.ExecuteApi("sendapply", data);
  };

  this.sendfree = (data) => {
    return this.ExecuteApi("sendapplyfree", data);
  };
}