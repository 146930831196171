import './App.scss';
import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { Welcome } from './vista/welcome';
import { version, Test, AppConfig } from '@essenza/core';
import { AppRoot, useBreakPoint } from '@essenza/react';
import { AppSchema } from './schema';
import { MainLayout } from './layout/MainLayout';
import { JobVista } from './vista/JobVista';
import { InsertStudentVista } from './vista/InsertStudentVista';
import { InsertCompanyVista } from './vista/InsertCompanyVista';
import { StudentFormVista } from './vista/StudentFormVista';
import { CompanyFormVista } from './vista/CompanyFormVista';
import { UploadStudentVista } from './vista/UploadStudentVista';
import { UploadCompanyVista } from './vista/UploadCompanyVista';
import { AreaModel, ContractModel, NotyModel, PlaceModel, RoleModel, SkillModel } from './models/DBModel';
import { JobFormVista } from './vista/JobFormVista';
import { ProfileVista } from './vista/ProfileVista';
import { SettingVista } from './vista/SettingVista';
import { NotificationVista } from './vista/NotifictionVista';
import { CompanyListVista } from './vista/CompanyListVista';
import { JobDetailVista } from './vista/JobDetailVista';
import { ConatctVista } from './vista/ConatctVista';
import { UserModel } from './models/model';
import { MobileLayout } from './layout/MobileLayout';
import { JobVistaMobile } from './vista/mobile/JobVistaMobile';
import { LoginVista } from './vista/profile/LoginVista';
import { RecoverVista } from './vista/profile/RecoverVista';
import { FirstAccessVista } from './vista/profile/FirstAccessVista';

/**
 * 
 * @param {VistaApp} app 
 */
function initApp(app) {
  console.log("INIT APP", app);
  AppConfig.serviceRoute = "service/app/"
  //VistaJS.DEVELOPMENT = true;
  app.model.read(SkillModel, m => m.list());
  app.model.read(RoleModel, m => m.list());
  app.model.read(PlaceModel, m => m.list());
  app.model.read(ContractModel, m => m.list());
  app.model.read(AreaModel, m => m.list());
  
  //app.model.request(UserModel, m=>m.test({id: 2, mutated: {id: 1, Tname: "Property", tname: "Field", tsurname:"surname", test:"test", jskill: [1,2,3]}}));
  return null;
}

/**
 * 
 * @param {VistaApp} app 
 */
function onlogin(app) {
  console.log("ON LOGIN", app);
  app.model.read(NotyModel, m => m.list()).then((r) => {
    if (Array.isArray(r)) {
      console.log("NOTIFICATION-LEN", r.length);
      console.log("COUNT A", r.length);
      app.model.setGlobal("noty", r.length);
      console.log("COUNT B", r.length);
    }
  });

  if(app.session.complete)
    app.navigate("/offerte");
  else
    app.navigate("/profile");
  //app.model.read(CategoryModel, m=>m.list(null, true));
}

function AppController(c) {
  c.skin = App;
  c.command = null;
}
//https://localhost:44370/  http://networkssati.iconsultant.it/  https://network.ssati.it/
function App() {
  console.log("START VISTA APP", version, Test);
  const nav = useNavigate();
  const [qp] = useSearchParams();
  const token = useRef();
  const breakpoint = useBreakPoint('md');
  return (
    <>
      <AppRoot token={token} init={initApp} control={AppController} navigator={nav} qp={qp} onlogin={onlogin} basename="/admin" baseurl="https://network.ssati.it/" schema={AppSchema}>
        <Routes>
          {breakpoint.md.active ?
            <Route path="/" element={<MainLayout token={token} />}>
              
              <Route path="offerte" element={<JobVista />} />
              <Route path="istudente" element={<StudentFormVista />} />
              <Route path="profile" element={<ProfileVista />} />
              <Route path="aziende" element={<CompanyListVista />} />
              <Route path="dettaglio" element={<JobDetailVista />} />
              <Route path="contatto" element={<ConatctVista />} />
              <Route path="userform" element={<StudentFormVista />} />
              <Route path="notifications" element={<NotificationVista />} />
            </Route>
            :
            <Route path="/" element={<MobileLayout token={token} />}>
              {/* <Route index element={<Welcome token={token} />} /> */}
              <Route path="offerte" element={<JobVistaMobile />} />
              <Route path="istudente" element={<StudentFormVista />} />
              <Route path="profile" element={<ProfileVista />} />
              <Route path="aziende" element={<CompanyListVista />} />
              <Route path="dettaglio" element={<JobDetailVista />} />
              <Route path="contatto" element={<ConatctVista />} />
              <Route path="userform" element={<StudentFormVista />} />
              <Route path="notifications" element={<NotificationVista />} />
            </Route>
          }
          <Route index element={<Welcome token={token} />} />
          <Route path="login" element={<LoginVista />} />
          <Route path="loginrec" element={<RecoverVista />} />
          <Route path="primoaccesso" element={<FirstAccessVista />} />
        </Routes>
      </AppRoot>
      {/* <PopUp /> */}

      {/* <Routes>
          {breakpoint.md.active
            ? <Route path="/" element={<MainLayout model={AppModel} />}>
              <Route index element={<HomeVista />} />
              <Route path="stazioni" element={<StationVista />} />
              <Route path="satellite" element={<SatelliteVista />} />
              <Route path="bollettino" element={<BollettinoVista />} />
            </Route>
            :
            <Route path="/" element={<MobileLayout model={AppModel} />}>
              <Route index element={home} />
              <Route path="mobilemenu" element={<MobileMenuVista />} />
              <Route path="mobilestation" element={<MobileStationVista />} />
              <Route path="mobilew" element={<WarningView />} />
            </Route>
          }
          <Route path="mobilechannel" element={<MobileChannelVista />} />
        </Routes> */}
    </>
  );
}

export default App;
