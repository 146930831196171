import { AppModel } from "@essenza/core";
import { Formix, FormixItem, useForm, useControl, useGraph } from "@essenza/react";
import { Col, Button, Form, Input, Row, Upload, Checkbox, Select, Divider, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import * as yup from 'yup';
import { SkillModel } from "../models/DBModel";
import ImgCrop from 'antd-img-crop';

const { Option } = Select;

function ProfileFormController(c) {
    c.skin = ProfileForm;
    c.command = {
        SAVE: async (msg, { control }) => {
            //console.log("SAVE", source);
            let form = control.form("profile-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                if (!result.data.complete)
                    result.node.mutate("complete", true, result.data);

                if (result.data.__mutation?.mutated.temail) {
                    result.node.mutate("username", result.data.temail, result.data);
                    result.node.mutate("nemail", result.data.temail.toUpperCase(), result.data);
                }
                result.node.save().then((r) => {
                    c.navigate("/offerte");
                    if (msg)
                        msg.success('Profilo aggiornato con successo!', 2); //()=>control.navigate("/")
                });
            }
        },
        CHANGE_PASSWORD: async (path, { control, model }) => {
            let form = control.form("password-form");
            let result = await form.validate();
            console.log("PASSWORD FORM VALIDATION", form, result);
            if (result.isValid) {
                model.request(AppModel, m => m.changePassword(result.data).then((r) => {
                    if (path)
                        control.navigate(path);
                    else
                        control.closePopup();
                }));
            }
        }
    }
}

export function ProfileForm({ source, label, title, npath }) {
    //const source = useGraph2(LessonModel, "item");
    const [control] = useControl(ProfileFormController);
    const skills = useGraph(SkillModel, "list");
    const form = useForm("profile-form", source, control, null, yup.object({
        temail: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
        /*npassword: yup.string().required("Password è una informazione richiesta.").matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
            "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
        ),
        cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function (value) {
            return this.parent.npassword === value;
        }),*/
    })); //, {itype: (v) => v === "1"}
    const pform = useForm("password-form", source, control, null, yup.object({
        temail: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
        npassword: yup.string().required("Password è una informazione richiesta.").matches(
            /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,24}$/,
            "Deve contenere Almeno 8 Caratteri, almeno una maiuscola, almeno una minuscola, almeno un umero ed almeno un carattere speciale"
        ),
        cpassword: yup.string().required("Conferma password richiesta.").test('passwords-match', 'Passwords non corrispondenti', function (value) {
            return this.parent.npassword === value;
        }),
    })); 
    const [refresh, setRefresh] = useState(null);
    const img = source.data?.img;
    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            //name: 'immagineprofilo.jpg',
            status: 'done',
            url: (img || '/img/profile/user.png') + '?' + new Date().getTime(),
        },
    ]);

    const onChange = ({ fileList: newFileList }) => {
        newFileList[1].status = "done";
        setFileList([newFileList[1]]);
    };

    const onchange2 = ({ fileList: newFileList }) => {
        if (newFileList) {
            newFileList[newFileList.length - 1].status = "done";
        }

        //setFileList([newFileList[1]]);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const nominative = source.data ? source.data.tsurname + '_' + source.data.tname : '';

    const ibeforeUpload = (file) => {
        if (file.type === 'image/jpeg' || file.type === 'image/png')
            return true;
        else {
            control.openPopup(<div>Formato file non supporto. Formato supportato jpg</div>)
            return Upload.LIST_IGNORE;
        }
    };

    const ionSuccess = (r) => {
        //source.node.mutate("cvdate", new Date(), source.data);
        //setRefresh(new Date());
        message.success("Immagine caricata con successo!");
    }

    const beforeUpload = (file) => {
        if (file.type === 'application/pdf')
            return true;
        else {
            control.openPopup(<div>Formato file non supporto. Formato supportato pdf</div>)
            return Upload.LIST_IGNORE;
        }
    };

    const onSuccess = (r) => {
        source.node.mutate("cvdate", new Date().toISOString().split('T')[0], source.data);
        setRefresh(new Date());
        message.success("Curriculum caricato con successo!");
    }

    const handleChange = (value, option) => console.log("SELECTION", value, option);
    return (
        <>
            <Formix control={control} form={form} layout='vertical' >
                <div className="custom-form bg-pri round-sm mt-sm padding-xl text-left">
                    <h3>Informazioni personali</h3>
                    <Divider />
                    <FormixItem label="Nome" name="tname">
                        <Input>
                        </Input>
                    </FormixItem>
                    <FormixItem label="Cognome" name="tsurname">
                        <Input >
                        </Input>
                    </FormixItem>
                    <FormixItem label="E-mail" name="temail">
                        <Input >
                        </Input>
                    </FormixItem>
                    <FormixItem label="Città Residenza" name="tsite">
                        <Input >
                        </Input>
                    </FormixItem>
                    <FormixItem label="Carica Immagine del profilo" name="taddress" extra="Formato consigliato 300px x 300px">
                        <ImgCrop rotate shape="round" >
                            <Upload name="image" beforeUpload={ibeforeUpload} onSuccess={ionSuccess} customRequest={control.upload} data={{ url: 'api/uprofile' }}
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                            >
                                {fileList.length < 2 && <div className="block-pri w100 h100"><UploadOutlined className="pr-sm" />Carica</div>}
                            </Upload>
                        </ImgCrop>
                    </FormixItem>
                    <div className="right-align">
                        <Button className="primaryButton" onClick={() => control.execute("SAVE", message)}>
                            Salva
                        </Button>
                    </div>
                </div>
                <div className="custom-form bg-pri round-sm mt-sm padding-xl text-left">
                    <h3>Esperienze Lavorative</h3>
                    <Divider />
                    <FormixItem label="Carica Curriculum Vitae" name="taddress" extra={source.data?.cvdate ? 'Ultimo CV caricato il ' + new Date(source.data.cvdate).toLocaleDateString('it-IT') : ""}>
                        <Upload name="cv" beforeUpload={beforeUpload} onChange={onchange2} onSuccess={onSuccess} customRequest={control.upload} data={{ nominative: nominative, url: 'api/ucv' }}>
                            <Button className="primaryButton w100" icon={<UploadOutlined />} block>
                                Carica
                            </Button>
                        </Upload>
                    </FormixItem>
                    <FormixItem label="link al Protfolio" name="tportfolio">
                        <Input placeholder="Es. https://mioportfolio.it" >
                        </Input>
                    </FormixItem>
                    <div className="right-align">
                        <Button className="primaryButton" onClick={() => control.execute("SAVE", message)}>
                            Salva
                        </Button>
                    </div>
                </div>
                <div className="custom-form bg-pri round-sm mt-sm padding-xl text-left">
                    <h3>Formazione e Skills</h3>
                    <Divider />
                    <FormixItem label="In SSATI ho seguito il corso in" name="mkt" valuePropName="checked">
                        <Checkbox>Marketing</Checkbox>
                    </FormixItem>
                    <FormixItem name="ctrl" valuePropName="checked">
                        <Checkbox>Controllo di gestione</Checkbox>
                    </FormixItem>
                    <FormixItem name="tech" valuePropName="checked">
                        <Checkbox>Tecnologie Industriali</Checkbox>
                    </FormixItem>
                    <FormixItem label="Skills" name="jskills" extra="Inserisci competenze, attitudini o software che sai utilizzare">
                        <Select mode="multiple" allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Seleziona Competenze"
                            onChange={handleChange}>
                            {skills.data ? skills.data.map((item) => <Option value={item.id}>{item.name}</Option>) : null}
                        </Select>
                    </FormixItem>
                    <div className="right-align">
                        <Button className="primaryButton" onClick={() => control.execute("SAVE", message)}>
                            Salva
                        </Button>
                    </div>
                </div>
                <div className="custom-form bg-pri round-sm mt-sm padding-xl text-left">
                    <h3>Modifica password</h3>
                    <Divider />
                    <Formix control={control} form={pform} layout='vertical'>
                        <FormixItem label="Password attuale" name="tpassword">
                            <Input.Password autoComplete="new-password" >
                            </Input.Password>
                        </FormixItem>
                        <FormixItem label="Nuova password" name="npassword">
                            <Input.Password>
                            </Input.Password>
                        </FormixItem>
                        <FormixItem label="Conferma password" name="cpassword">
                            <Input.Password>
                            </Input.Password>
                        </FormixItem>
                        <div className="right-align">
                            <Button className="primaryButton" onClick={() => control.execute("CHANGE_PASSWORD", "/offerte")}>
                                Conferma
                            </Button>
                        </div>
                    </Formix>
                </div>
                {/*                <div className="right-align">
                    <Button className="primaryButton" onClick={() => control.execute("SAVE", "/home")}>
                        Aggiorna
                    </Button>
                </div> */}
            </Formix>
        </>
    )
}