import { Col, Layout, Menu, Row, Select } from "antd";

import { Vista } from "@essenza/react";
import { useModel, useVista, useGraph, useApp } from "@essenza/react";
import { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";
import { JobModel } from "../../models/jobmodel";
import { JobOpen, JobOpenMobile } from "../../view/JobOpen";
import { JobSent, JobSentMobile } from "../../view/JobSent";
import { JobDetail } from "../../view/jobdetail";
import { JobSaved, JobSavedMobile } from "../../view/JobSaved";

const { Sider, Content } = Layout;
const { Option } = Select;

export function JobVistaController(c) {
    c.skin = JobVistaMobile;
    let store = null;
    c.command = {
        SEARCH: (filter, { data, node }) => {
            if (!store)
                store = data;
            const area = filter.area;
            c.setSource(node.getKey(), store.filter((item) => (area === '' || item.irole === area)));
        }
    }
}

export function JobVistaMobile(info) {
    const [ctx, control] = useVista(JobVistaController);
    const [model] = useModel();
    const location = useLocation();
    const [index, setIndex] = useState(location.state || "0");
    const filter = useRef({ area: '', index: 0 }).current;
    const open = useGraph(JobModel, "open");
    const sent = useGraph(JobModel, "applied");
    const saved = useGraph(JobModel, "saved");
    const source = [open, sent, saved][index];
    console.log("JOB-VISTA", index);

    useEffect(() => {
        if (model) {
            if (index === "0") {
                model.read(JobModel, m => m.open());
            }
            else if (index === "1") {
                model.read(JobModel, m => m.sent());
            }
            else
                model.read(JobModel, m => m.saved());

            control.store = null;
        }
    }, [index, model]);

    return (
        <Vista context={ctx} >
            <div className="w100 bg-qua" >

                <Menu inlineIndent={48} className="hmenu centered" mode="horizontal" onClick={({ key }) => setIndex(key)} items={menuItems} selectedKeys={index} defaultSelectedKeys={['0']}></Menu>

                {/* <div className="secondary-header  secondary-header-shadow">
                    <Row className="max-width px-sm">
                        <Col flex="auto" style={{ display: "flex", alignItems: "center" }}>
                            <h1 style={{ paddingLeft: '54px' }}>{['Posizioni Aperte', 'Candidature Inviate', 'Annunci Salvati'][index]}</h1>
                        </Col>
                        <Col flex="none">
                            <Select onChange={(v) => { filter.area = v; control.execute("SEARCH", filter, source) }} placeholder="Filtra per categoria" style={{ width: '250px', height: '50px', padding: '0' }}>
                                <Option value="">Tutti i settori</Option>
                                <Option value={1}>Marketing</Option>
                                <Option value={2}>Controllo di Gestione</Option>
                                <Option value={3}>Tecnologie Industriali</Option>
                            </Select>
                        </Col>
                    </Row>
                </div> */}

                <section className="bg-sec h-sec">
                    <div className="max-width">
                        {index === "0" && <JobOpenMobile source={open?.get()} />}
                        {index === "1" && <JobSentMobile source={sent?.get()} />}
                        {index === "2" && <JobSavedMobile source={saved?.get()} />}
                    </div>
                </section>
            </div>
        </Vista>
    )
}

const menuItems = [
    { label: 'Aperte', key: "0" }, // remember to pass the key prop
    { label: 'Inviate', key: "1" },
    { label: 'Salvate', key: "2" }
];