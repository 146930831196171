import { DataModel } from "@essenza/core";

export function JobModel() {
  DataModel.call(this, "joboffer");

  this.open = () => {
    return this.ExecuteApi("open: [joboffer] {*}");
  };

  this.sent = () => {
    return this.ExecuteApi("applied: [joboffer] {*}");
  };

  this.saved = () => {
    return this.ExecuteApi("saved: [joboffer] {*}");
  };
}