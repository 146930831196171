import { DataSource } from "@essenza/core";
import {Vista, useVista} from "@essenza/react";
import { useLocation } from "react-router-dom";
import { JobDetail } from "../view/jobdetail";
import { JobForm } from "../view/job_form";
 

function JobDetailVistaController(c) {
    c.skin = JobDetailVista;
}

export function JobDetailVista() {
    const [ctx, control] = useVista(JobDetailVistaController);
    const job = useLocation().state;

    //console.log("LESSON STATE ID");
    return (
        <Vista context={ctx} >
            <div className="w100 py-md">
                <div className="centered max-width-sm">
                    {/* <h1 className="spacing-b10">{job?'Aggiorna Offerta':'Trova il tuo prossimo talento!'}</h1> */}
                    <h1 className="spacing-b10 ml-sm"><span className="pointer" onClick={()=>control.navigate(-1)}>&larr;</span></h1>
                    <JobDetail source={new DataSource(job)} />
                </div>
            </div>
        </Vista>
    )
}

/*
const [model] = useModel();
    const job = useGraph(JobModel, "item");
    const idjob = useLocation().state;

    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(JobModel, m => m.item(idjob));
        }
    }, [model, idjob]);

    if (!job.data) return null;
 */