import { Button, Form, Input, Select } from "antd";
import { Formix, FormixItem, useForm, useControl, useGraph } from "@essenza/react";
import { ContractModel, PlaceModel, SkillModel } from "../models/DBModel";

const { Option } = Select;
const { TextArea } = Input;

function JobFormController(c) {
    c.skin = JobForm;
    c.command = {
        SAVE: async (path, { control, data }) => {
            //console.log("SAVE", source);
            let form = control.form("job-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            const v = result.form.getFieldsValue(true);

            if (result.isValid) {
                result.node.mutate("idcompany",3,result.data);
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path, data);
                    else
                        control.closePopup();
                });
                //In futuro dovrà avvenire automaticamente in datagraph model, così come save su error ripristina dati originali
            }
        },
    }
}

export function JobForm({ source, label, npath, ndata }) {
    const [control] = useControl(JobFormController);
    const form = useForm("job-form", source, control);
    const skills = useGraph(SkillModel, "list");
    const places = useGraph(PlaceModel, "list");
    const contracts = useGraph(ContractModel, "list");
    console.log("SELECTION", skills);
    const handleChange = (value, option) => console.log("SELECTION", value, option);
    return (
        <>
            <Formix control={control} form={form} layout='vertical' onValuesChange={(v) => console.log("CHANGED", v)} >
                <div className="custom-form bg-pri round-sm mt-sm padding-xl text-left">
                    <FormixItem label="Titolo posizione aperta" name="title">
                        <Input placeholder="Es. marketing Intership"></Input>
                    </FormixItem>
                    <FormixItem label="Luogo di lavoro" name="jobtype">
                        <Select style={{ width: '50%', minWidth: '350px' }}>
                            {Array.isArray(places.data) && places.data.map((v) => <Option value={v.name}>{v.name}</Option>)}
                        </Select>
                    </FormixItem>
                    <FormixItem label="Località del lavoro" name="place">
                        <Input placeholder="Es.Firenze"></Input>
                    </FormixItem>
                    <FormixItem label="Tipo di impiego" name="contract">
                        <Select style={{ width: '50%', minWidth: '350px' }}>
                            {Array.isArray(contracts.data) && contracts.data.map((v) => <Option value={v.name}>{v.name}</Option>)}
                        </Select>
                    </FormixItem>
                </div>
                <div className="custom-form bg-pri round-sm mt-sm padding-xl">
                    <FormixItem label="Aggiungi una descrizione del lavoro" name="description">
                        <TextArea rows={15}></TextArea>
                    </FormixItem>
                    <FormixItem label="Competenze Richieste" extra="Aggiungi parole chiave relative alle competenze per dare più visibilità alla tua offerta fra le persone giuste." >
                        <Select mode="multiple" allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Seleziona Competenze"
                            onChange={handleChange}>
                            {skills.data ? skills.data.map((item) => <Option value={item.id}>{item.name}</Option>) : null}
                        </Select>
                    </FormixItem>
                    <Button className="primaryButton" onClick={() => control.execute("SAVE", npath)}>{source.data.id?'Salva modifiche':'Crea annuncio'}</Button>
                </div>
            </Formix>
        </>
    )
}