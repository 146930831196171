import { Row, Col, Button} from 'antd';
import React, { useEffect } from 'react';
import {useGraph, useModel, useVista, Vista } from '@essenza/react';
import { UserModel } from '../models/model';
import { ProfileForm } from '../view/ProfileForm';
import { useLocation } from 'react-router-dom';
import { ContactForm } from '../view/ContactForm';

function ConatctVistaController(c) {
    c.skin = ConatctVista;
}

export function ConatctVista({ vmodel }) {
    const [ctx, control] = useVista(ConatctVistaController);
    const [model] = useModel();
    const user = useGraph(UserModel, "profile");
    const info = useLocation().state;
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(UserModel, m => m.profile());
        }
    }, [model]);

    if (!user.data) return null;

    return (
        <Vista context={ctx} >
            <div className="w100 py-md">
                <div className="centered max-width-sm text-center">
                    <h1 style={{ marginBottom: '0' }} className='pt-md pb-sm'>Inoltra candidatura a {info?.company}</h1>
                    {info?.title && <h2 className='mb-md'>{info.title}</h2>}
                    <ContactForm source={user} info={info} />
                </div>
            </div>
        </Vista>
    )
}