import { Button, Col, Form, Input } from "antd";
import { Formix, FormixItem, useForm, useControl } from "@essenza/react";
import { AppModel } from "@essenza/core";

function StudentFormController(c) {
    c.skin = StudentForm;
    c.command = {
        SAVE: async (path, { control, data }) => {
            //console.log("SAVE", source);
            let form = control.form("user-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                result.node.save().then((r) => {
                    if (path)
                        control.navigate(path, data);
                    else
                        control.closePopup();
                });
            }
        },
        INVITEIN: async (route, { model }) => {
            let form = c.form("user-form");
            let result = await form.validate();
            console.log("COURSE FORM VALIDATION", form, result);
            if (result.isValid) {
                route = !result.data.itype ? "/admin" : "";
                model.read(AppModel, m => m.invitein(result.data, route).then(r => c.navigate("/settings")));
            }
        }
    }
}

export function StudentForm({ source, label, isUpdate, route, npath, ndata }) {
    const [control] = useControl(StudentFormController);
    const form = useForm("user-form", source, control);
    const onclick = isUpdate
        ? () => control.execute("SAVE", npath)
        : () => control.execute("INVITEIN", route);
    return (
        <Formix control={control} form={form} layout='vertical' className="custom-form bg-pri round-sm padding-xl">
            <FormixItem label="Nome" name="tname">
                <Input placeholder="Mario"></Input>
            </FormixItem>
            <FormixItem label="Cognome" name="tsurname">
                <Input placeholder="Rossi"></Input>
            </FormixItem>
            <FormixItem label="Email" name="temail">
                <Input placeholder="studente@email.it"></Input>
            </FormixItem>
            <Col span={24} className="right-align">
                <Button className="primaryButton" onClick={onclick}> {label || "Inserisci"} </Button>
            </Col>
        </Formix>
    )
}