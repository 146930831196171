import {Vista, useVista, useGraph} from "@essenza/react";
import { useLocation } from "react-router-dom";
import { UserModel } from "../models/model";
import {StudentForm} from "../view/student_form";

function StudentFormVistaController(c) {
    c.skin = StudentFormVista;
}

export function StudentFormVista() {
    const [ctx, control] = useVista(StudentFormVistaController);
    const user = useGraph(UserModel, "item");
    const state = useLocation().state;
    //console.log("LESSON STATE ID");
    //Inserisci dei nuovi studenti in SSATI Network
    return (
        <Vista context={ctx} >
            <div className="w100 py-md">
                <div className="centered max-width-sm text-center">
                    <h1 className="spacing-b10"><span className="pointer" onClick={()=>control.navigate(-1)}>&lt;</span> Inserisci nuovo utente </h1>
                    <h6 style={{ marginLeft: "28px" }} className="spacing-b30">Metodo inserimento&#47;<b>Singolo Contatto</b></h6>
                    <StudentForm label={state?.label} isUpdate={state?.isUpdate} npath="settings" source={user} />
                </div>
            </div>
        </Vista>
    )
}