import { Button, Col, Form, Input, message, Upload } from "antd";
import { Formix, FormixItem, useForm, useControl } from "@essenza/react";
import { FileService } from "@essenza/core";
import { UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { JobService } from "../service/JobService";
import { useState } from "react";

function StudentFormController(c) {
    c.skin = ContactForm;
    c.command = {
        SEND: async (info, { control }) => {
            //console.log("SAVE", source);
            let form = control.form("user-form");
            let result = await form.validate();
            console.log("CONTACT FORM VALIDATION", form, result);
            if (result.isValid) {
                info.euser = result.data.temail;
                info.phone = result.data.phone;
                info.location = result.data.tsite;
                if(result.data.cvdate)
                    info.cv = result.data.cvdate;

                result.node.save();
                if (info.idcompany) {
                    control.request(JobService, s => s.sendfree(info).then((r) => {
                        message.success("Candidatura inviata con successo!", 2);
                        control.navigate("/aziende");
                    }));
                }
                else {
                    control.request(JobService, s => s.send(info).then((r) => {
                        control.navigate("/offerte", "1");
                    }));
                }
            }
        },
    }
}

export function ContactForm({ source, info }) {
    const [control] = useControl(StudentFormController);
    const form = useForm("user-form", source, control, null, yup.object({
        temail: yup.string().required("Email è una informazione richiesta.").email("Formato email non corretto"),
        phone: yup.string().required("Telefono è una informazione richiesta."),
    }));
    const [refresh, setRefresh] = useState(null);
    const beforeUpload = (file) => {
        if (file.type === 'application/pdf')
            return true;
        else {
            control.openPopup(<div>Formato file non supporto. Formato supportato pdf</div>)
            return Upload.LIST_IGNORE;
        }
    };

    const onSuccess = (r) => {
        source.node.mutate("cvdate", new Date(), source.data);
        setRefresh(new Date());
        message.success("Curriculum caricato con successo!");
    }

    const student = source.data;
    if(!student) return null;
    const nominative = student.tsurname + '_' + student.tname;
    info.nominative = nominative;
    if(student.tportfolio)
        info.portfolio = student.tportfolio;
    info.location = student.tsite;   
    return (
        <Formix control={control} form={form} layout='vertical' className="custom-form bg-pri round-sm padding-xl text-left">
            <FormixItem label="Email" name="temail">
                <Input placeholder="studente@email.it"></Input>
            </FormixItem>
            <FormixItem label="Telefono" name="phone">
                <Input placeholder="+39"></Input>
            </FormixItem>
            {
                source.data?.cvdate
                    ? <h6>Curriculum caricato in data {new Date(source.data.cvdate).toLocaleDateString('it-IT')}</h6>
                    : null
            }
            <Upload name="cv" beforeUpload={beforeUpload} onSuccess={onSuccess} customRequest={control.upload} data={{ nominative: nominative, url: 'api/ucv' }}>
                <Button className="primaryButton w100 " icon={<UploadOutlined />} block>
                    {source.data?.cvdate ? "Aggiorna Curriculum" : "Carica Curriculum"}
                </Button>
            </Upload>
            <Col span={24} className="right-align">
                <Button className="primaryButton" onClick={() => control.execute("SEND", info, source)}>Invia Candidatura</Button>
            </Col>
        </Formix>
    )
}