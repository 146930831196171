import { Col, Row } from "antd";
import { Vista, useVista } from "@essenza/react";
import { ReactComponent as Logo } from '../../assets/svg/logo-ssati-network.svg';
import { LoginRecover } from "../../view/profile/login_recover";

function RecoverVistaController(c) {
    c.skin = RecoverVista;
}

export function RecoverVista() {
    const [ctx] = useVista(RecoverVistaController);
    return (
        <Vista context={ctx} >
            <div className='w100 h100 bg-sec'>
                <div className='block-md-width centered'>
                    <Row className='content-max-width h-main padding-sm' align="middle">
                        <Col span={24} className="bg-pri padding-xl">
                            <Logo style={{ verticalAlign: "top" }} alt="Logo" className="mb-sm img-resize mt-md" />
                            <h1 className="my-md" >
                                Recupero Password
                            </h1>
                            {/* <h6 className="my-md" >Inserisci la mail con cui sei iscritto</h6> */}
                            <LoginRecover />
                        </Col>
                    </Row>
                </div>
            </div>
        </Vista>
    )
}