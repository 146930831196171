import { Outlet, useLocation } from "react-router-dom";
import { Avatar, Badge, Button, Col, Layout, Menu, Popover, Row, Tooltip } from "antd";
import React, { useEffect, useState } from 'react';
import { useApp, useGlobal } from "@essenza/react";
import { ReactComponent as Impostazioni } from '../assets/svg/impostazioni.svg';
import { UserOutlined, SettingOutlined } from '@ant-design/icons';
import { ReactComponent as Logo } from '../assets/svg/logo-ssati-network.svg';
import logoLgs from "../assets/img/logo_lgs.png";
import { ReactComponent as JobIcon } from "../assets/svg/job-icon.svg";
import { ReactComponent as CompanyIcon } from '../assets/svg/companies-icon.svg';
import { ReactComponent as StudentIcon } from '../assets/svg/student-icon.svg';
import { ReactComponent as NewIcon } from '../assets/svg/new-application-icon.svg';
// import { ReactComponent as CompaniesIcon } from '../assets/svg/companies-icon.svg';
import { ReactComponent as NotificationIcon } from '../assets/svg/notification-icon.svg';

const { Header, Content } = Layout;

export function MainLayout({ token }) {
  const app = useApp();
  const [count, setCount] = useGlobal("noty", 0);
  const control = token.current?.control;
  const model  = token.current?.model;
  const location = useLocation();
  console.log("COUNT", count);
  /*useEffect(()=>{
    if(control){
      control.setGlobal("noty", 0, setCount);
    }
  },[control])*/

  return (
    <Layout className="layout">
      {/* <AppSider model={App.model}></AppSider> */}
      <Layout className="layout">
        <Header className="layout-header">
          {/* {app.logged &&  */}
          <Row align="middle" className="h100">
            <Col flex="none">
              <Logo style={{ verticalAlign: "top" }} alt="Logo" />
              {/* <img src={logoLgs} height="50" className="my-auto" alt="Logo Laboratorio Geofisica"></img> */}
            </Col>
            <Col flex="auto">

            </Col>
            {/*  <Col flex="400px">
              <Menu mode="horizontal">
                <Menu.Item icon={<JobIcon />}>
                  Job
                </Menu.Item>
                <Menu.Item icon={<NewIcon />}>
                  Inserisci
                </Menu.Item>
              </Menu>
            </Col> */}
            <Col flex="40px" className="avatar-column hover-fill">
              <span className="pointer" onClick={() => control.navigate("/offerte")}>
                <div>
                  <JobIcon className={'icon ' + (location?.pathname === '/offerte' ? 'iselected' : '')} />
                </div>
                <h6 className={"text-center " + (location?.pathname !== '/offerte' ? 'uselected' : '')}>
                  Annunci
                </h6>
              </span>
            </Col>
            <Col flex="40px" className="avatar-column hover-fill">
              <span className="pointer" onClick={() => control.navigate("/aziende")}>
                <div>
                  <CompanyIcon className={'icon ' +(location?.pathname === '/aziende' ? 'iselected' : '')} />
                </div>
                <h6 className={"text-center " + (location?.pathname !== '/aziende' ? 'uselected' : '')}>
                  Aziende
                </h6>
              </span>
            </Col>
            <Col flex="auto">

            </Col>
            <Col flex="60px" className="avatar-column">
            <Badge count={count}>
              <Tooltip placement="bottom" title="Notifiche" color="#264395">
                <NotificationIcon className={'pointer ' + (location?.pathname === '/corsi' ? 'iselected' : '')} onClick={() => control.navigate("/notifications")} />
              </Tooltip>
            </Badge>
            </Col>
            <Col flex="none" className="avatar-column">
              <Tooltip placement="bottom" title="Profilo" color="#264395">
                <Avatar className="pointer" onClick={() => control.navigate("/profile")} size={36} icon={<UserOutlined />} />
              </Tooltip>
            </Col>
          </Row>
          {/* } */}
        </Header>
        <Content
          className="layout-bg layout-content"
          style={{
            padding: 0,
            minHeight: 280,
          }}
        >
          <Outlet></Outlet>
        </Content>
      </Layout>
    </Layout>
  );
}