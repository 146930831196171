import { useControl, useGraph } from "@essenza/react";
import { Avatar, Button, Col, List, message, Row } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { JobService } from "../service/JobService";
import { UserModel } from "../models/model";
import { AreaModel } from "../models/DBModel";

function CompanyListController(c) {
    c.skin = CompanyList;
    c.info = null;
    c.send = (node) => c.request(JobService, s => s.sendfree(c.info).then((r) => {
        message.success("Candidatura inviata con successo!", 2);
        node.refresh();
    }));

    c.command = {
        SEND: async (info, { item, model, node }) => {
            c.navigate("/contatto", info);
            /*item.dapplied = new Date().toISOString().split('T')[0];
            if (c.info) {
                c.info.ecompany = info.ecompany;
                c.info.idcompany = info.idcompany;
                c.info.dapplied = info.dapplied;
                c.send(node);
            }
            else {
                model.read(UserModel, m => m.profile().then(user => {
                    info.nominative = user.tsurname + ' ' + user.tname;
                    info.euser = user.temail;
                    info.cv = user.cvdate;
                    info.phone = user.phone;
                    c.info = info;
                    c.send(node);
                }));
            }*/
        },
    }
}
const range=['0-10', '11-49', '50-249', '+249']
export function CompanyList({ source, editable, span }) {
    const [control] = useControl(CompanyListController);
    const roles = useGraph(AreaModel, "list");
    if (!source.data) return null;

    const isApplied = date => date && ((new Date().getTime() - new Date(date).getTime()) < (90000 * 3600 * 2))

    return (
        <div>
            <List dataSource={source.getCollection()}
                renderItem={company => (
                    <List.Item>
                        <Row align="middle" className="w100 padding-sm pt-sm round-sm shadow-x" style={{ backgroundColor: "white" }}>
                            <Col flex="76px" >
                            { company.img?<img src={company.img + '?' + new Date().getTime()} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                            </Col>
                            <Col flex="auto" className="py-sm">
                                <h2 >{company.tbusinessname}</h2>
                                <div className="mt-sm">{company.taddress + ', ' + (company.cap || '') + ' ' + company.tsite}</div>
                                <h6>{company.irange && range[company.irange-1] + ' dipendenti'}</h6>
                                <Row align="middle">
                                    <Col span={24}  md={4} >{company.twebsiteurl && <a href={company.twebsiteurl} target="_blank" rel="noreferrer">Sito Web</a>}</Col>
                                    <Col span={8} sm={8} md={7} className="pl-media">Settore Business: </Col>
                                    <Col span={11} sm={10} md={7} className="bg-sec padding-xs">{roles.data && company.idbusiness?roles.data[company.idbusiness-1].name:''}</Col>
                                </Row>
                            </Col>
                            <Col flex="none">
                                {isApplied(company.dapplied)
                                    ? <h5 className="centered py-sm">Candidatura inviata il {new Date(company.dapplied).toLocaleDateString("it-IT")}</h5>
                                    : <Button onClick={() => control.navigate("/contatto", { ecompany: company.temail, idcompany: company.id, dapplied: company.dapplied, company: company.tbusinessname })} className="primaryButton">Invia candidatura</Button>
                                }
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </div>
    )
}

//<Button onClick={() => control.execute("SEND", { ecompany: company.temail, idcompany: company.id, dapplied: company.dapplied }, source, null, { item: company })} className="primaryButton">Invia candidatura</Button>