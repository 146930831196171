import { Avatar, Button, Col, Divider, Row, Tag } from "antd";
import { useControl } from "@essenza/react";
import { GraphNode } from "@essenza/core";
import { UserOutlined } from '@ant-design/icons';
import { JobService } from "../service/JobService";

function JobDetailController(c) {
    c.skin = JobDetail;
    c.command = {
        APPPLY: (job) => {
            c.request(JobService, r => r.apply(job).then(()=>c.navigate("contatto", {ecompany: job.temail, company: job.tbusinessname, title: job.title})));
        },
        SAVE: (item, { node }) => {
            c.request(JobService, r => r.save(item));
        }
    }
}

//<Button onClick={()=>control.navigate("/overview")}><CaretLeftOutlined /></Button>

export function JobDetail({ source, setter }) {
    const [control] = useControl(JobDetailController);
    const job = source.data;
    if (!job) return null; //loader??? qui o in vista o comunque chi lo usa
    return (
        <div className="padding-md text-left" >
            <Row align="middle" className="w100 padding-md round-sm bg-pri">
                <Col className="text-center" flex="92px">
                    { job.img?<img src={job.img + '?' + new Date().getTime()} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                </Col>
                <Col className="" flex="auto">
                    <h2>{job.title}</h2>
                    <h6 className="pt-sm">{job.tbusinessname}</h6>
                    <p style={{ margin: 0 }}>{job.taddress + ', ' + (job.cap || '') + ' ' + job?.tsite}</p>
                </Col>
                <Col className="text-left" flex="auto">
                    {!job.applied && <Button onClick={() => control.execute("APPPLY", job)} className="primaryButton mt-sm mr-sm">Candidati</Button>}
                    {!job.saved &&<Button onClick={() => control.execute("SAVE", job)} className="secondaryButton  mt-sm">Salva</Button>}
                </Col>
            </Row>
            <Row className="bg-pri round-sm my-md padding-md">
                <Col span={24} md={12}>
                    <p>Luogo di lavoro <span style={{ fontWeight: "bold" }}>{job.jobtype}</span></p>
                    <p>Località del lavoro <span style={{ fontWeight: "bold" }}>{job.place}</span></p>
                    <p>Tipo di impiego <span style={{ fontWeight: "bold" }}>{job.contract}</span></p>
                </Col>
                <Col span={24} md={12}>
                    <h5 className="pb-sm">Competenze Richieste</h5>
                    {
                        job.skills? job.skills.map(s=><Tag>{s.name}</Tag>):null
                    }
                </Col>
            </Row>
            <div className="w100 bg-pri round-sm padding-md" >
                <h4>Descrizione</h4>
                <p className="pt-sm">{job.description}</p>
            </div>
        </div>
    )
}
