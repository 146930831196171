import { Row, Col, Button, Input, Select, Badge } from 'antd';
import { MailOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useRef } from 'react';
import { UserList } from '../view/UserList';
import { useGraph, useModel, useVista, Vista } from '@essenza/react';
import { UserModel } from '../models/model';


const { Option } = Select;

function SettingVistaController(c) {
    c.skin = SettingVista;
    let store;
    c.command = {
        ADD: (source, { model }) => {
            //Aggiungo una lezione che appartiene ad elenco lezioni ma nel form devo gestire un singolo item lession ???
            //Forse possiamo gestire tutto tramite classe DataSource.

            const data = model.addData(source, { itype: 1 }); //
            c.setSource("users.item", data);
            c.navigate("userform");
            //c.openPopup(<UserForm source={new DataSource(data, source.node)} />, "Nuovo Utente", null, {excludeOk: true, excludeCancel: true});
        },

        SEARCH: (list, { data }) => {
            if (!store)
                store = list;
            const txt = data.text.toLowerCase();
            const d = data.domain;
            c.setSource("users.list", store.filter((item) => (txt === '' || (item.tsurname?.toLowerCase() + ' ' + item.tname?.toLowerCase()).indexOf(txt) > -1 || item.temail?.toLowerCase().indexOf(txt) > -1) && (d === '' || item.itype === d )));
        }
    }
}

export const domains = ['bvlg.bcc.it', 'bancavaldarno.bcc.it', 'bancacentro.it', 'bancadipisa.it', 'bancaelba.it', 'bancatema.bcc.it', 'bancofiorentino.it', 'bat.bcc.it',
    'bccas.it', 'bccvaldarnofiorentino.it', 'bpc.bcc.it', 'ft.bcc.it', 'pontassieve.bcc.it', 'vivalbanca.bcc.it'];

export function SettingVista({ vmodel }) {
    const [ctx, control] = useVista(SettingVistaController);
    const [model] = useModel();
    const users = useGraph(UserModel, "list");
    //const [count, setCount] = useState(0);
    const filter = useRef({ text: '', domain: '' }).current;

    console.log("USER-LIST-SETTING", users);
    let count = 0;
    if (users.data)
        count = users.data.length;

    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(UserModel, m => m.list());
        }
    }, [model]);
    return (
        <Vista context={ctx}>
            <div className="w100 py-md">
                <div className="centered max-width text-center">
                
                    <Row gutter={16} align="middle" className="padding-md">
                        <Col flex="none">
                        <h2 style={{ marginBottom: "0" }}>Elenco utenti</h2>
                        </Col>
                        <Col flex="none">
                            <Badge color="#264395" count={count}>
                                <MailOutlined style={{ fontSize: '32px' }} />
                            </Badge>
                        </Col>
                        <Col flex="Auto">
                            <Input className="input search-filter" onChange={(e) => { filter.text = e.currentTarget.value; control.execute("SEARCH", users.data, filter) }} prefix={<SearchOutlined />} placeholder="Cerca utente" >
                            </Input>
                        </Col>
                        <Col flex="none">
                            <Select onChange={(v) => { filter.domain = v; control.execute("SEARCH", users.data, filter) }} placeholder="Utenti" style={{ width: '100%', height: '50px', padding: '0' }}>
                                <Option value="">Tutti gli utenti</Option>
                                <Option value={0}>Admin</Option>
                                <Option value={1}>User</Option>
                                {/* <Option value={2}>Studente</Option> */}
                            </Select>
                        </Col>
                        <Col flex="none">
                            <Button  className='primaryButton' onClick={() => control.execute("ADD", users)} >
                                Nuovo utente
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} >
                            <UserList source={users} />
                        </Col>
                    </Row>
                </div>
            </div>
        </Vista>
    )
}