import { Col, Row } from "antd";
import {Vista, useVista} from "@essenza/react";
import { ReactComponent as Logo } from '../../assets/svg/logo-ssati-network.svg';
import LoginScreen from '../../assets/img/login-ssati-image.jpg';
import { Login } from "../../view/profile/login_form";

function LoginVistaController(c) {
    c.skin = LoginVista;
}

export function LoginVista() {
    const [ctx] = useVista(LoginVistaController);
    return (
        <Vista context={ctx} >
            <div className='w100 h100 bg-sec'>
                <div className='block-max-width centered'>
                    <Row className='content-max-width h-main padding-sm' align="middle" justify="center">
                        <Col span={0} md={12} className="">
                            <div>
                                <img src={LoginScreen} className="h-block"  style={{ minWidth: "541px" }} alt="SSati Login"></img>
                            </div>
                        </Col>
                        <Col span={24} md={12} className="bg-pri padding-xl h-block ">
                            <Logo style={{ verticalAlign: "top"}} alt="Logo" className="mb-sm img-resize mt-md" />
                            <h5 className="my-md">Accedi</h5>
                            <Login nosignin />
                        </Col>
                    </Row>
                </div>
            </div>
        </Vista>
    )
}