import { useControl } from "@essenza/react";
import { Avatar, Col, List, Row } from "antd";
import { UserOutlined, StarOutlined, StarFilled } from '@ant-design/icons';
import { JobService } from "../service/JobService";


function JobOpenController(c) {
    c.skin = JobOpen;
    c.command = {
        DETAIL: async (item) => {
            if (item.seen)
                c.navigate("dettaglio", item);
            else {
                c.request(JobService, r => r.seen(item).then((r) => {
                    item.idjoboffer = item.id;
                    c.navigate("dettaglio", item);
                }));
            }
        },

        SAVE: (item, { node }) => {
            c.request(JobService, r => r.save(item).then(() => node.refresh()));
        }
    }
}

export function JobOpen({ source }) {

    const [control] = useControl(JobOpenController);
    if (!source.data) return null;
    const onclick = (e, item) => {
        e.stopPropagation();
        control.execute("SAVE", item, source)
    }
    const t = '?' + new Date().getTime();
    return (
        <List dataSource={source.getCollection()}
            renderItem={item => (
                <List.Item className="po-sm">
                    <Row align="middle" onClick={() => control.execute("DETAIL", item)} className="pointer w100 py-sm round-sm shadow-hx" style={{ backgroundColor: item.seen ? "#E1E7FA" : "white" }}>
                        <Col className="text-center" flex="92px">
                            { item.img?<img src={item.img  + t} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                        </Col>
                        <Col className="" flex="auto">
                            <h2>{item.title}</h2>
                            <h6 className="pt-sm">{item.tbusinessname}</h6>
                            <p style={{ margin: 0 }}>{item.tsite}</p>
                        </Col>
                        <Col className=" pr-lg  text-right" flex="none">
                            {item.saved
                                ? <StarFilled onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: "#192857" }} />
                                : <StarOutlined onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: "#192857" }} />
                            }
                            <p style={{ paddingTop: '32px', margin: 0 }}>{new Date(item.idate).toLocaleDateString("it-IT")}</p>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

export function JobOpenMobile({ source }) {

    const [control] = useControl(JobOpenController);
    if (!source.data) return null;
    const onclick = (e, item) => {
        e.stopPropagation();
        control.execute("SAVE", item, source)
    }
    const t = '?' + new Date().getTime();
    return (
        <List dataSource={source.getCollection()}
            renderItem={item => (
                <List.Item className="po-sm">
                    <div onClick={() => control.execute("DETAIL", item)} className="pointer w100 padding-sm round-sm shadow-hx" style={{ backgroundColor: item.seen ? "#E1E7FA" : "white" }}>
                        <Row align="middle"  >
                            <Col flex="auto">
                                { item.img?<img src={item.img  + t} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                            </Col>
                            <Col className="  text-right" flex="none">
                                {item.saved
                                    ? <StarFilled onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: "#192857" }} />
                                    : <StarOutlined onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: "#192857" }} />
                                }
                            </Col>
                        </Row>
                        <Row className="mt-sm">
                            <Col span={24}>
                                <h2>{item.title}</h2>
                                <h6 className="pt-sm">{item.tbusinessname}</h6>
                                <p style={{ margin: 0 }}>{item.tsite}</p>
                            </Col>
                        </Row>
                        <p style={{ paddingTop: '32px', margin: 0, textAlign: 'right' }}>{item.idate}</p>
                    </div>
                </List.Item>
            )}
        />
    )
}