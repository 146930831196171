import { useControl } from "@essenza/react";
import { Avatar, Col, List, Row } from "antd";
import { UserOutlined, CheckCircleFilled } from '@ant-design/icons';


function JobApprovedController(c) {
    c.skin = JobSent;
    c.command = {
        DETAIL: async (item) => {
            c.navigate("dettaglio", item);
        },
    }
}

//new Date(item.tapplied + 'Z').toString() 
export function JobSent({ source, editable, span }) {
    const [control] = useControl(JobApprovedController);

    if (!source.data) return null;
    const onclick = (e, item) => {
        e.stopPropagation();
        control.execute("SAVE", item, source)
    }
    const t = '?' + new Date().getTime();
    return (
        <List dataSource={source.getCollection()}
            renderItem={item => (
                <List.Item className="po-sm">
                    <Row align="middle" onClick={() => control.execute("DETAIL", item)} className="pointer w100 py-sm round-sm shadow-hx" style={{ backgroundColor: "white" }}>
                        <Col className="text-center" flex="92px">
                        { item.img?<img src={item.img + t} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                        </Col>
                        <Col className="" flex="auto">
                            <h2>{item.title}</h2>
                            <h6 className="pt-sm">{item.tbusinessname}</h6>
                            <p style={{ margin: 0 }}>{item.tsite}</p>
                        </Col>
                        <Col className=" pr-lg  text-right" flex="none">
                            <CheckCircleFilled onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: item.seen ? "#192857" : "#EDEDED" }} />
                            <p style={{ paddingTop: '32px', margin: 0 }}>{'Inviata il ' + new Date(item.tapplied).toLocaleDateString("it-IT")}</p>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    )
}

export function JobSentMobile({ source, editable, span }) {
    const [control] = useControl(JobApprovedController);

    if (!source.data) return null;
    const onclick = (e, item) => {
        e.stopPropagation();
        control.execute("SAVE", item, source)
    }
    const t = '?' + new Date().getTime();
    return (
        <List dataSource={source.getCollection()}
            renderItem={item => (
                <List.Item className="po-sm">
                    <div onClick={() => control.execute("DETAIL", item)} className="pointer w100 padding-sm round-sm shadow-hx" style={{ backgroundColor: "white" }}>
                        <Row align="middle"  >
                            <Col flex="auto">
                                { item.img?<img src={item.img  + t} width="64" height="64" alt="Immagine Profilo" />:<Avatar size={64} icon={<UserOutlined />} />}
                            </Col>
                            <Col className="  text-right" flex="none">
                                <CheckCircleFilled onClick={(e) => onclick(e, item)} style={{ fontSize: '18px', color: item.managed ? "#192857" : "#EDEDED" }} />
                            </Col>
                        </Row>
                        <Row className="mt-sm">
                            <Col span={24}>
                                <h2>{item.title}</h2>
                                <h6 className="pt-sm">{item.tbusinessname}</h6>
                                <p style={{ margin: 0 }}>{item.tsite}</p>
                            </Col>
                        </Row>
                        <p style={{ paddingTop: '32px', margin: 0, textAlign: 'right' }}>{item.managed ? item.tmanaged : 'Inviata il ' + new Date(item.tapplied).toLocaleDateString("it-IT")}</p>
                    </div>
                </List.Item>
            )}
        />
    )
}

//item.tapplied?.split('T')[0]