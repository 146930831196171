import { DataModel } from "@essenza/core";

export function SkillModel() {
    DataModel.call(this, "skill");
  
    this.list = () => {
      this.ExecuteQuery("list: [skill] {*}", null, true);
    };
  }

  export function RoleModel() {
    DataModel.call(this, "role");
  
    this.list = () => {
      this.ExecuteQuery("list: [role] {*}", null, true);
    };
  }

  export function ContractModel() {
    DataModel.call(this, "jobcontract");
  
    this.list = () => {
      this.ExecuteQuery("list: [jobcontract] {*}", null, true);
    };
  }

  export function PlaceModel() {
    DataModel.call(this, "jobplace");
  
    this.list = () => {
      this.ExecuteQuery("list: [jobplace] {*}", null, true);
    };
  }

  export function AreaModel() {
    DataModel.call(this, "jobarea");
  
    this.list = () => {
      this.ExecuteQuery("list: [jobarea] {*}", null, true);
    };
  }

  export function NotyModel() {
    DataModel.call(this, "notification");
  
    this.list = () => {
      return this.ExecuteApi("list: [notification] {*}", null, "snoty", true);
    };

    this.all = () => {
      return this.ExecuteApi("all: [notification] {*}", null, "seenoty");
    };
  }