import { Outlet, useLocation } from "react-router-dom";
import { Avatar, Badge, Button, Col, Layout, Row, Tooltip } from "antd";
import React from 'react';
import { useApp, useGlobal } from "@essenza/react";
import { UserOutlined} from '@ant-design/icons';
import { ReactComponent as Logo } from '../assets/svg/logo-ssati-network.svg';
import { ReactComponent as JobIcon } from "../assets/svg/job-icon.svg";
import { ReactComponent as CompanyIcon } from '../assets/svg/companies-icon.svg';
import { ReactComponent as NotificationIcon } from '../assets/svg/notification-icon.svg';

const { Header, Content } = Layout;

export function MobileLayout({ token }) {
    const app = useApp();
    const [count, setCount] = useGlobal("noty", 0);
    const control = token.current?.control;
    const model = token.current?.model;
    const location = useLocation();
    console.log("COUNT", count);

    return (
        <Layout className="layout">
            <Layout className="layout">
                <Header className="layout-header" style={{height: '50px'}}>
                    <Row align="middle" className="h100">
                        <Col flex="auto">
                            <Logo style={{ verticalAlign: "top", height: '36px', width: 'auto' }} alt="Logo" />
                        </Col>
                        <Col flex="60px" className="avatar-column">
                            <Badge count={count}>
                                <Tooltip placement="bottom" title="Notifiche" color="#264395">
                                    <NotificationIcon className={'pointer ' + (location?.pathname === '/corsi' ? 'iselected' : '')} onClick={() => control.navigate("/notifications")} />
                                </Tooltip>
                            </Badge>
                        </Col>
                        <Col flex="none" className="avatar-column">
                            <Tooltip placement="bottom" title="Profilo" color="#264395">
                                <Avatar className="pointer" onClick={() => control.navigate("/profile")} size={36} icon={<UserOutlined />} />
                            </Tooltip>
                        </Col>
                    </Row>
                </Header>
                <Content className="layout-bg layout-content" style={{padding: 0, minHeight: 280,}}>
                    <Outlet></Outlet>
                </Content>
                <Header className="layout-header" style={{height: '50px'}}>
                    <Row justify="center">
                        <Col flex="40px" className="avatar-column hover-fill">
                            <span className="pointer" onClick={() => control.navigate("/offerte")}>
                                <div>
                                    <JobIcon className={'icon ' + (location?.pathname === '/offerte' ? 'iselected' : '')} />
                                </div>
                            </span>
                        </Col>
                        <Col flex="40px" className="avatar-column hover-fill">
                            <span className="pointer" onClick={() => control.navigate("/aziende")}>
                                <div>
                                    <CompanyIcon className={'icon ' + (location?.pathname === '/aziende' ? 'iselected' : '')} />
                                </div>
                            </span>
                        </Col>
                    </Row>
                    {/* } */}
                </Header>
            </Layout>
        </Layout>
    );
}