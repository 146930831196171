import { Vista, useVista, useGraph, useModel } from "@essenza/react";
import { Button, Divider } from "antd";
import { useEffect } from "react";
import { NotyModel } from "../models/DBModel";

export function NotificationVistaController(c) {
    c.skin = NotificationVista;
    c.command = {
        ALL: (_,{model}) =>{
            model.read(NotyModel, m=>m.all());
        }
    }
}

export function NotificationVista(info) {
    const [ctx, control] = useVista(NotificationVistaController);
    const notifications = useGraph(NotyModel, "list");
    const all = useGraph(NotyModel, "all");
    const [model] = useModel();
    useEffect(() => {
        return () => model.setGlobal("noty", 0);
    }, [model]);
    const format = (n) =>{
        if(n.tmessage)
            return "L’azienda " + n.tbusinessname.toUpperCase() + " ti ha appena inviato un messaggio. Controlla la tua posta elettronica per non perderti il messaggio."
        else
            return "La tua candidatura per la posizione " + n.title.toUpperCase() + " nell’azienda " + n.tbusinessname.toUpperCase() + " è stata vista."
            //return "Ciao, l’azienda " + n.tbusinessname.toUpperCase() + " ha creato un nuovo annuncio lavorativo che è in attesa di essere approvato!"
    }
    return (
        <Vista context={ctx} >
            <div className="w100 py-md">
                <div className="centered max-width-sm text-center padding-sm">
                    <h1>Le ultime notifiche</h1>
                    {Array.isArray(notifications.data)
                        ? notifications.data.map((n) => <div className="w100 py-sm mt-sm round-sm  bg-pri"><h6>{format(n)}</h6></div>)
                        : <h2 className="mt-sm">Nessuna nuova notifica presente!</h2>}
                    <Divider />
                    {all.data ? <h2>Notifiche precedenti</h2> : <Button onClick={()=>control.execute("ALL")} className="primaryButton">Carica Notifiche precedenti</Button>}
                    {Array.isArray(all.data) && all.data.map((n) => <div className="w100 py-sm mt-sm round-sm bg-pri"><h6>{format(n)}</h6></div>)
                    }
                    {all.data && !all.data.length && <h2 className="py-sm">Nessuna notifica presente!</h2>}
                </div>
            </div>
        </Vista>
    )
}