import { Vista } from "@essenza/react";
import { useModel, useVista, useGraph } from "@essenza/react";
import { Col, Row, Select } from "antd";
import { useEffect, useRef } from "react";
import { AreaModel, RoleModel } from "../models/DBModel";
import { UserModel } from "../models/model";
import { CompanyList } from "../view/CompanyList";

const { Option } = Select;

export function CompanyListVistaVistaController(c) {
    c.skin = CompanyListVista;
    let store = null;
    c.command = {
        SEARCH: (list, { data }) => {
            if (!store)
                store = list;
            const id = data.area;
            c.setSource("users.companies", store.filter((item) => (id === '' || (item.idbusiness === id))));
        }
    }
}

export function CompanyListVista(info) {
    const [ctx, control] = useVista(CompanyListVistaVistaController);
    const [model] = useModel();
    const companies = useGraph(UserModel, "companies");
    const roles = useGraph(AreaModel, "list");
    const filter = useRef({ area: '' }).current;
    useEffect(() => {
        console.log("MODEL ", model);
        if (model) {
            model.read(UserModel, m => m.companies());
        }
    }, [model]);

    console.log("CANDIDATE-LIST-VISTA");

    return (
        <Vista context={ctx} >
            <div className="w100 py-md px-sm">
                <div className="centered max-width-sm">
                    <Row>
                        <Col flex="auto"><h1>Elenco Aziende</h1></Col>
                        <Col className="flex-sm">
                            <Select onChange={(v) => { filter.area = v; control.execute("SEARCH", companies.data, filter) }} placeholder="Settore di business" className="select-flex-w">
                                <Option value="">Tutti i settori</Option>
                                {Array.isArray(roles.data) && roles.data.map((v) => <Option value={v.id}>{v.name}</Option>)}
                            </Select>
                        </Col>
                    </Row>
                    <CompanyList source={companies?.get()} />
                </div>
            </div>
        </Vista>
    )
}